:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

  img {
    vertical-align: middle;
    border-style: none; }

progress {
  vertical-align: baseline; }

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

  .sticky-nav {
    position: sticky;
    top: 0;
  }
 
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; 
}
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }
.pl-3,
.px-3 {
  padding-left: 1rem !important; }

  .page {
    width: 100%;
    margin-bottom: 7em; }
    .page.one{
      margin-bottom:0;
    }
    .page.two{
      margin-bottom: 0;
    }
    .page.four {
      margin-bottom: 0; }
    .page .heading {
      font-weight: 800;
      font-size: 30px;
      margin-bottom: 30px;
      color: #3e64ff; }
.bg-white {
  background-color: #fff !important; }
.rounded-lg {
  border-radius: 0.3rem !important; }
.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;}
.p-4 {
  padding: 1.5rem !important; }
  .resume-wrap {
    width: 100%;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px; }
    .resume-wrap .icon {
      width: 50px;
      height: 50px;
      background: #3e64ff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%; }
      .resume-wrap .icon span {
        color: #fff;
        font-size: 28px; }
    .resume-wrap .text {
      width: calc(100% - 50px); }
    .resume-wrap .date {
      font-weight: 700;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
      color: #3e64ff; }
    .resume-wrap h2 {
      font-size: 24px;
      font-weight: 700; }
    .resume-wrap .position {
      font-size: 18px;
      font-weight: 700;
      color: #000000; }

.mouse {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 120px;
  z-index: 2; }
.mouse-icon {
  width: 70px;
  height: 70px;
  border: 1px solid transparent;
  cursor: pointer;
  position: relative;
  text-align: center;
  margin: 0 auto;
  display: block; }
  .mouse-icon:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: #edf0ff;
    z-index: -1;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.mouse-wheel {
  height: 70px;
  margin: 2px auto 0;
  display: block;
  width: 30px;
  background: transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: 1.6s ease infinite wheel-up-down;
  -moz-animation: 1.6s ease infinite wheel-up-down;
  animation: 1.6s ease infinite wheel-up-down;
  color: #3e64ff;
  font-size: 30px; }
  
@-webkit-keyframes wheel-up-down {
  100% {
    margin-top: 25px;
    opacity: 1; }
  30% {
    opacity: 1; }
  0% {
    margin-top: -10px;
    opacity: 0; } }

@-moz-keyframes wheel-up-down {
  100% {
    margin-top: 25px;
    opacity: 1; }
  30% {
    opacity: 1; }
  0% {
    margin-top: -10px;
    opacity: 0; } }

@keyframes wheel-up-down {
  100% {
    margin-top: 25px;
    opacity: 1; }
  30% {
    opacity: 1; }
  0% {
    margin-top: -10px;
    opacity: 0; } }
.justify-content-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important; }
.align-items-stretch {
          -webkit-box-align: stretch !important;
          -ms-flex-align: stretch !important;
          align-items: stretch !important; }
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  -ms-grid-row-align: stretch !important;
  align-self: stretch !important; }
.align-items-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important; }
        .hero-wrap {
          width: 100%;
          height: 100%;
          position: inherit;
          background-size: cover;
          background-repeat: no-repeat; }
          .hero-wrap .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            opacity: .1;
            width: 50%;
            background: #3e64ff; }
          .hero-wrap .text {
            width: 100%; }
        
.slider-text .subheading {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 800;
  color: #3e64ff;
  letter-spacing: 4px; }
  .slider-text h1 {
    font-size: 60px;
    font-weight: 800; }
    @media (max-width: 767.98px) {
      .slider-text h1 {
        font-size: 40px; } }
        .slider-text h2 {
          font-weight: 800; 
        }
          .slider-text h2 span {
            color: #3e64ff;
            text-decoration: underline; }

img {
  vertical-align: middle;
  border-style: none; }
.ftco-about .img-about {
  width: 100%;
  z-index: 0;
  position: relative; }
  .ftco-about .img-about .img {
    display: block;
    width: 100%;
    position: relative;
    z-index: 1; }
.ftco-section {
  padding: 7em 0;
  position: relative; }
  @media (max-width: 767.98px) {
    .ftco-section {
      padding: 6em 0; } }



.ftco-about ul.about-info {
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 100%; }
  .ftco-about ul.about-info li {
    list-style: none;
    margin-bottom: 10px; }
    .ftco-about ul.about-info li span {
      width: calc(100% - 130px); }
      .ftco-about ul.about-info li span:first-child {
        font-weight: 600;
        color: #000000;
        width: 130px; }

.ftco-about .counter-wrap .text p {
  font-size: 20px; }
  .ftco-about .counter-wrap .text p span {
    font-weight: 400;
    color: #000000; }
  .ftco-about .counter-wrap .text p span.number {
    font-weight: 600;
    color: #3e64ff; }


.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }
.ftco-no-pt {
  padding-top: 0 !important; }

.ftco-no-pb {
  padding-bottom: 0 !important; } 

  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  @media print {
    *,
    *::before,
    *::after {
      text-shadow: none !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important; }
    a:not(.btn) {
      text-decoration: underline; }
    abbr[title]::after {
      content: " (" attr(title) ")"; }
    pre {
      white-space: pre-wrap !important; }
    pre,
    blockquote {
      border: 1px solid #adb5bd;
      page-break-inside: avoid; }
    thead {
      display: table-header-group; }
    tr,
    img {
      page-break-inside: avoid; }
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3; }
    h2,
    h3 {
      page-break-after: avoid; }
    @page {
      size: a3; }
    body {
      min-width: 992px !important; }
    .container {
      min-width: 992px !important; }
    .navbar {
      display: none; }
    .badge {
      border: 1px solid #000; }
    .table {
      border-collapse: collapse !important; }
      .table td,
      .table th {
        background-color: #fff !important; }
    .table-bordered th,
    .table-bordered td {
      border: 1px solid #dee2e6 !important; }
    .table-dark {
      color: inherit; }
      .table-dark th,
      .table-dark td,
      .table-dark thead th,
      .table-dark tbody + tbody {
        border-color: #dee2e6; }
    .table .thead-dark th {
      color: inherit;
      border-color: #dee2e6; } }
.heading-section {
  position: relative; }
  .heading-section .subheading {
    font-size: 13px;
    font-weight: 500;
    display: block;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: black;
    letter-spacing: 3px; }
  .heading-section h1.big {
    position: absolute;
    top: 0px;
    left: 0;
    font-size: 7vw;
    color: rgba(255, 255, 255, 0.1);
    z-index: -1;
    font-weight: 900; }
    .heading-section h1.big.big-2 {
      right: 0; }
  .heading-section h2 {
    font-size: 50px;
    font-weight: 700; }
    .heading-section h2 span {
      font-weight: 400; }
    @media (max-width: 767.98px) {
      .heading-section h2 {
        font-size: 38px; } }
  .heading-section.heading-section-white .subheading {
    color: rgba(255, 255, 255, 0.9) !important; }
  .heading-section.heading-section-white h2 {
    color: #fff; }
  .heading-section.heading-section-white p {
    color: rgba(255, 255, 255, 0.9); }

@media (max-width: 768px) {
  #navi {
    display: none;
  }
}

#navi {
  top: 180px;
  position: -webkit-sticky;
  position: sticky;
  margin: 0; }
  #navi ul {
    margin: 0;
    padding: 0; }
  #navi li {
    font-weight: 700;
    list-style: none;
    margin-bottom: 10px; }
    #navi li a {
      color: #000000; }
      #navi li a.current {
        color: #3e64ff;
        margin-left: 20px;
        position: relative; }
        #navi li a.current:after {
          position: absolute;
          top: 50%;
          left: -24px;
          width: 20px;
          height: 2px;
          content: '';
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          background: #3e64ff; }

.w-100 {
  width: 100% !important; }
.h-100 {
  height: 100% !important; }
.rounded-circle {
  border-radius: 50% !important; }

  @-webkit-keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0; }
    to {
      background-position: 0 0; } }
  
  @keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0; }
    to {
      background-position: 0 0; } }
  
  .progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem; }
  
  .progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .progress-bar {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  
  .progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem; }
  
  .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite; }
    @media (prefers-reduced-motion: reduce) {
      .progress-bar-animated {
        -webkit-animation: none;
        animation: none; } }

.progress-wrap {
  width: 100%;
  margin-bottom: 30px; }
  .progress-wrap h3 {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500; }
.border-primary {
  border-color: #007bff !important; }
.progress {
  height: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #e6e6e6;
  overflow: visible; }

.progress-bar {
  background: #3e64ff;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 12px;
  line-height: 1.2;
  color: #000000;
  font-weight: 600;
  position: relative;
  overflow: visible;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px; }
  .progress-bar:after {
    position: absolute;
    top: -2px;
    right: 0;
    width: 34px;
    height: 34px;
    content: '';
    background: #3e64ff;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    border-radius: 2px;
    opacity: 0; }
    .progress-bar span {
      position: absolute;
      
      right: 5px;
      font-size: 14px;
      font-weight: 500;
      color: #e6e6e6; }

.progress-circle .progress {
  width: 150px;
  height: 150px;
  background: none;
  position: relative; }

.progress-circle .progress::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #eee;
  position: absolute;
  top: 0;
  left: 0; }

.progress-circle .progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1; }

.progress-circle .progress .progress-left {
  left: 0; }

.progress-circle .progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 6px;
  border-style: solid;
  position: absolute;
  top: 0; }

.progress-circle .progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left; }

.progress-circle .progress .progress-right {
  right: 0; }

.progress-circle .progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  -ms-transform-origin: center right;
  transform-origin: center right; }

.progress-circle .progress .progress-value {
  position: absolute;
  top: 0;
  left: 0; }

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

  .ftco-footer {
    font-size: 16px;
    background: #000000;
    z-index: 0; }
    .ftco-footer .ftco-footer-logo {
      text-transform: uppercase;
      letter-spacing: .1em; }
    .ftco-footer .ftco-footer-widget h2 {
      font-weight: normal;
      color: #fff;
      margin-bottom: 0;
      font-size: 22px;
      font-weight: 600; }
    .ftco-footer .ftco-footer-widget ul li {
      margin-bottom: 0 }
      .ftco-footer .ftco-footer-widget ul li a {
        color: rgba(255, 255, 255, 0.6); }
        .ftco-footer .ftco-footer-widget ul li a span {
          color: rgba(255, 255, 255, 0.6); }
    .ftco-footer .ftco-footer-widget .btn-primary {
      background: #fff !important;
      border: 2px solid #fff !important; }
      .ftco-footer .ftco-footer-widget .btn-primary:hover {
        background: #fff;
        border: 2px solid #fff !important; }
    .ftco-footer p {
      color: rgba(255, 255, 255, 0.7); }
    .ftco-footer a {
      color: rgba(255, 255, 255, 0.7); }
      .ftco-footer a:hover {
        color: #fff; }
    .ftco-footer .ftco-heading-2 {
      font-size: 17px;
      font-weight: 400;
      color: #000000; }
  
  .ftco-footer-social li {
    list-style: none;
    margin: 0 10px 0 10px;
    display: inline-block; }
    .ftco-footer-social li a {
      height: 50px;
      width: 50px;
      display: block;
      float: left;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      position: relative; }
      .ftco-footer-social li a span {
        position: absolute;
        font-size: 26px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
      .ftco-footer-social li a:hover {
        color: #fff; }
  
  .footer-small-nav > li {
    display: inline-block; }
    .footer-small-nav > li a {
      margin: 0 10px 10px 0; }
      .footer-small-nav > li a:hover, .footer-small-nav > li a:focus {
        color: #3e64ff; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}